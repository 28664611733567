<template>
  <div>
    <b-card >
      <b-row >
        <b-col sm="12" md="3" class="text-center">
          <img
            width="25%"
            style="border-radius:50%;"
            src="@/assets/user_1.png"
            alt=""
          />

          <b-card-text>
            <p class="school32 text-center">Муҳитдинов Aбдулқодир<br />(1892 -1934 )</p></b-card-text
          >
        </b-col>
        <b-col sm="12" md="6">
           <b-card-text>
          <p class="TextBlack17">
            Муҳитдинов Aбдулқодир (1892, Бухоро — 1934.1.6, Тошкент) —
            Бухородаги жадидчилик ҳаракати намояндаси, сиёсат арбоби. Бошланғич
            таълимни Бухорода олгач, Истанбул мадрасасида ўқиди. Отаси йирик
            савдогар Мирза Муҳитдин Мансуров илк жадид газ. "Бухоройи шариф"ни
            моддий жиҳатдан таъминлаб турган, янги усул мактабларига ҳомийлик
            қилган. М.нинг илк мақолалари Оренбургдаги "Вақт" газ.да еълон
            қилинган (1910). Бухорода 1917 йил апрел намойишиаян кейин йўш
            бухороликлар партияси МКга аъзо бўлди. Колесов воқеасшхш кейин
            Самарқанд, Тошкент, Москвада муҳожирликда яшаган. Туркистон
            мусулмонлари бюроси аъзоси (1919—20). "Қу-тулиш" газ. муҳаррири
            (1920). Бухоро инқилобий кўмитаси раиси, БХСР деққончилик нозири
            (1920—21). БХСР нинг дастлабки Конституциясини ишлаб чиқишда
            қатнашган, Бухорода йерсув ислоҳоти ўтказилиши ташкилотчиларидан
            бири. М. БХСР нинг хорижий мамлакатлар билан Россия воситачилигисиз
            мустақил алоқа ўрнатиш, босқинчи қизил армияни олиб чиқиб кетиш,
            халқ милицияси сингари миллий армия тузишни қатъий талаб қилган.
            1921— 24 йилларда БХСР савдо ва саноат вазири, Бухоро Халқ хўжалиги
            Олий кенгаши раиси, Бухоро Иқтисодий кенгаши раиси, БХСР нозирлар
            шўроси раисининг 2-муовини лавозимларида ишлаган. Ўрта Осиёда миллий
            ҳудудий чегараланиш ўтказилиб, Ўзбекистон ССР таркибида Тожикистон
            AССР ташкил қилингач, М. Тожикистон AССР Халқ Комиссарлари Совета
            раиси қилиб тайинланди (1925—29). Тожикистон ССР тузилгач, у
            Ўзбекистон ССР таъминот Халқ Комиссарлигида ишлади. 1933 йил 21 авг
            .да совет режими томонидан М. қамоққа олиниб, унга "буржуа
            миллатчиси" там-ғаси ёпиштирилди ва 1934 йил 1 июнда отиб ташланди.
          </p>
        </b-card-text>
        </b-col>
        <b-col sm="12" md="3"></b-col>
      </b-row>
    </b-card>
    <!-- <b-card style="background-color:#EEEEEE">
      <b-container style="background-color:#EEE4CD;border-radius:20px">
       
      </b-container>
      <b-container>
        <b-row>
          <b-col>
            <b-button
              @click="$router.push({ name: 'jadidchilar_info' })"
              variant="outline-danger"
            >
              {{ $t("Back") }}
            </b-button>
          </b-col>
        </b-row>
      </b-container>
    </b-card> -->
  </div>
</template>
<script>
export default {};
</script>
<style></style>
